<template>
    <div>
      <b-row class="auth-inner m-0">
    
        <!-- <b-col>
          <h1 class="brand-text text-warning ml-1">
            موبايل مصر <span class="logo-span badge btn-info "> بائعين</span>
          </h1>
        </b-col> -->
      </b-row>
      <b-container
        class="bv-example-row bv-example-row-flex-cols"
      
      >
        <b-row align-v="stretch">
            <b-col class="mb-2" md="12" sm="12">
          <!-- <div v-for="(produc, index) in productsR" :key="index"></div> -->
          <CardTopProducts
            class="h-100"
            :title="$t('Product_Index.Products')"
            :color="'color:#003F91'"
            :paginationOption="true"
          />
          
          
        </b-col>
       
        </b-row>
      </b-container>
    </div>
  </template>
  
  <script>
  
  import CardTopProducts from '@/Components/card/CardTopProducts.vue'
  export default {
    components: {
      CardTopProducts,
    },
    data(){
        return{
        perPage: 3,
        currentPage: 1,
        totalRows:1,
        
        }
    },
 
    
    
  };
  </script>
  
  <style lang="scss">
  foreignObject {
    height: 200px;
  }
  #SvgjsSvg1001 {
    height: 230px;
  }
  .apexcharts-legend.position-bottom.apexcharts-align-center,
  .apexcharts-legend.position-top.apexcharts-align-center {
    display: none;
  }
  .btn-container {
    position: absolute;
    bottom: 10px;
    right: 20px !important;
  }
  .apexcharts-pie-label,
  .apexcharts-datalabels,
  .apexcharts-datalabel,
  .apexcharts-datalabel-label,
  .apexcharts-datalabel-value {
    letter-spacing: normal;
  }
  @media only screen and (max-width: 425px) {
    .brand-text {
      font-size: 20px;
    }
    .title-card {
      font-size: 20px;
    }
  }
  </style>
  